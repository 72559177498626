<template>
<div id="topnav"></div>
    <section class="register-area">
      <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-image">
            <img src="assets/img/register-bg.jpg" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div class="register-content">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="register-form">
                    <div class="logo">
                        <router-link :to="{ name: 'Home' }"><img src="@/assets/img/logo.png" alt="image" /></router-link>
                    </div>
                    <h3>Open up your Company Bow</h3>
                    <p>Trainer for company will be able to open up room for trainee of his/her own company.</p>
                    <form ref="clearForm" @submit.prevent="registerCompany" class="text-start">
                        <div class="row mt-4">
                            <div class="form-group">
                                <input type="text" v-model="form.name" placeholder="Company Name" class="form-control" />
                                <div v-if="errors.name" class="text-small text-danger text-start">{{ errors.name[0] }}</div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control " v-model="form.short_name" placeholder="Short Name">
                                <div v-if="errors.short_name" class="text-small text-danger text-start">{{ errors.short_name[0] }}</div>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control " v-model="form.email" placeholder="Company Email">
                                <div v-if="errors.email" class="text-small text-danger text-start">{{ errors.email[0] }}</div>
                                <div id="passwordHelpBlock" class="form-text">making sure you use company Email, we will need verify that</div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control " v-model="form.phone" placeholder="Company Phone Number">
                                <div v-if="errors.phone" class="text-small text-danger text-start">{{ errors.phone[0] }}</div>
                                <div id="passwordHelpBlock" class="form-text">we insist use of company phone numbers, not personal phone numbers.</div>
                            </div>
                            <div class="form-group">
                                <input type="year" class="form-control " v-model="form.founded_at" placeholder="Year Founded">
                                <div v-if="errors.founded_at" class="text-small text-danger text-start">{{ errors.founded_at[0] }}</div>
                            </div>
                            <div class="form-group">
                                <select class="form-control"  v-model="form.size">
                                    <option value="">Choose Company Size</option>
                                    <option value="1-9 Employees">1-9 Employees</option>
                                    <option value="10-49 Employees">10-49 Employees</option>
                                    <option value="50-99 Employees">50-99 Employees</option>
                                    <option value="100-499 Employees">100-499 Employees</option>
                                    <option value="500 or More Employees">500 or More Employees</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" cols="30" v-model="form.description" placeholder="Company Description"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button class="btn btn-primary" disabled v-if="submitting">
                                    <span class="label">{{ value }}</span>
                                </button>
                                <button type="submit" class="btn btn-primary" v-else>
                                    Create account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
        values: [],
        form: {
            email: '',phone: '', name: '', short_name: '',founded_at : '',size: '', vision: '', description: '',
        },
        error:null,
        submitting: false,
        token: localStorage.getItem('user_data'),
        errors:[]
    }
  },
  methods:{
    registerCompany(){
      axios.post('https://apitraining.vipawaworks.com/api/company/store', this.form).then(response => {
        response.data
        axios.post('https://apitraining.vipawaworks.com/api/auth/user/change_role_status/3', this.form).then(response => {
        response.data
          this.$router.push(this.$route.query.redirect || { name: 'TrainerDashboard'})
        })
      }).catch((error) => {
          this.errors = error.response.data.errors
      })
    },
  },
  created(){
    document.title = `Register Company - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
  }   

}
</script>

<style>

</style>